import React from "react";
import { useImportTemplate } from '../../hooks/useImportTemplate';
import { useExportTemplate } from '../../hooks/useExportTemplate';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, message, Spin } from 'antd';
import mjml from 'mjml-browser';
import { v4 as uuidv4 } from "uuid";
import { copy } from '../../utils/clipboard';
import { JsonToMjml, AdvancedType, BlockManager, BasicType } from 'easy-email-core';
import {
  EmailEditor,
  EmailEditorProvider,
  Stack,
  
} from 'easy-email-editor';
import OSS from "ali-oss";
import {  StandardLayout } from 'easy-email-extensions';
import '../CustomBlocks';
import '@arco-themes/react-easy-email-theme/css/arco.css';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import { getPosterMsg,getAssumeRole,updatePosterMsg } from "../api/poster";
// theme, If you need to change the theme, you can make a duplicate in https://arco.design/themes/design/1799/setting/base/Color


const defaultCategories = [
  {
    label: 'Content',
    active: true,
    blocks: [
      {
        type: AdvancedType.TEXT,
      },
      {
        type: AdvancedType.IMAGE,
        payload: { attributes: { padding: '0px 0px 0px 0px' } },
      },
      {
        type: AdvancedType.BUTTON,
      },
      {
        type: AdvancedType.SOCIAL,
      },
      {
        type: AdvancedType.DIVIDER,
      },
      {
        type: AdvancedType.SPACER,
      },
      {
        type: AdvancedType.HERO,
      },
      {
        type: AdvancedType.WRAPPER,
      },
    ],
  },
  {
    label: 'Layout',
    active: true,
    displayType: 'column',
    blocks: [
      {
        title: '2 columns',
        payload: [
          ['50%', '50%'],
          ['33%', '67%'],
          ['67%', '33%'],
          ['25%', '75%'],
          ['75%', '25%'],
        ],
      },
      {
        title: '3 columns',
        payload: [
          ['33.33%', '33.33%', '33.33%'],
          ['25%', '25%', '50%'],
          ['50%', '25%', '25%'],
        ],
      },
      {
        title: '4 columns',
        payload: [['25%', '25%', '25%', '25%']],
      },
    ],
  },
];

const fontList = [
  'Arial',
  'Tahoma',
  'Verdana',
  'Times New Roman',
  'Courier New',
  'Georgia',
  'Lato',
  'Montserrat',
  '黑体',
  '仿宋',
  '楷体',
  '标楷体',
  '华文仿宋',
  '华文楷体',
  '宋体',
  '微软雅黑',
].map(item => ({ value: item, label: item }));


class Poster extends React.Component {
  constructor() {
    super();
    this.state = {
      token: "",
      windowWidth: 0,
      windowHeihgt: 0,
      id: "",
      ordLot: "",
      initialValues: {},
      loading: true,
      data: {},
      updateLoading: false,
      downloadName: "download.mjml",
      mergeTags: {
      }
    }
  }
  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const ordLot = queryParams.get("ordLot");
    const token = queryParams.get("token");
    document.title = "Poster Design";
    window.addEventListener('resize', this.handleResize);
    this.setState({
      token: token,
      id: id,
      ordLot: ordLot,
    }, () => {
      this.fetchData();
    })
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  fetchData = () => {
    var data = {
      ordNum: this.state.id
    };
    getPosterMsg(data, this.state.token).then(response => {
      if (response.status === 200) {
        if (response.data.success) {
          let extInfo = {};
          try {
            extInfo = JSON.parse(response.data.result.extInfo);
          } catch (e) {
            extInfo = {};
          }
          //BlockManager.getBlockByType(BasicType.PAGE).create({}), ...extInfo
          this.setState({
            loading: false,
            data: { ...response.data.result, extInfo },
            initialValues: { subject: response.data.result.title ?? "", subTitle: "", content: BlockManager.getBlockByType(BasicType.PAGE).create({}), ...extInfo },
          }, () => {
          })
        } else {
          message.error('Error')
        }
      }
    })
      .catch(error => {
        // 请求失败，处理错误
        console.error('Error fetching data: ', error);
      });
  }

  onCopyHtml = (values) => {
    const html = mjml(JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content
    }), {
      beautify: true,
      validationLevel: 'soft',
    }).html;
    copy(html);
    message.success('Copied to pasteboard!')
  }

  onImportMjml = async () => {
    const { importTemplate } = useImportTemplate();
    try {
      const [filename,data] = await importTemplate();
      // setTemplate(data);
      this.setState(
        {
          initialValues: {
            ...this.state.initialValues,
            content: data,
          }
        }
      )
    } catch (error) {
      message.error('Invalid mjml file');
    }
  };

  onUploadImage = async (blob) => {
    var isImage = blob.type.includes("image");
    var fileName = blob.name;
    let id = uuidv4().replaceAll('-', '');
    var newFileName = id + fileName.substring(fileName.lastIndexOf("."), fileName.length);
    var key = 'poster/template/'+this.state.ordLot + '/' + newFileName;
    // /common/getAssumeRole
    let data = {}
    let response = await getAssumeRole(data, this.state.token)
    if (response.status === 200) {
      if (response.data.success) {
        const data = response.data.result;
        let client = new OSS({
          accessKeyId: data.accessKeyId,
          accessKeySecret: data.accessKeySecret,
          stsToken: data.securityToken,
          region: data.region,
          bucket: data.bucket,
          secure: true,
        })
        const headers = {
          // 指定Object的访问权限。
          'x-oss-object-acl': 'public-read',
        };
        let res = await client.put(key, blob, { headers: headers });
        if (res.res.status === 200) {
          message.success('Done')
          return res.url;
        }
      } else {
        message.error('Failed')
        return "";
      }
    } else {
      message.error('Failed')
      return "";
    }

  }

  onSubmit = (values) => {
    const html = mjml(JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content
    }), {
      beautify: true,
      validationLevel: 'soft',
    }).html;
    let body = {
      ...this.state.data,
      extInfo: JSON.stringify(values),
      infoBody: html,
    };
    this.setState({
      updateLoading: true
    })
    updatePosterMsg(body, this.state.token).then(response => {
      if (response.status === 200) {
        if (response.data.success) {
          this.setState({
            updateLoading: false
          })
          message.success('Saved success!')
        } else {
          this.setState({
            updateLoading: false
          })
          message.error('Failed')
        }
      } else {
        this.setState({
          updateLoading: false
        })
        message.error('Failed')
      }
    })
      .catch(error => {
        this.setState({
          updateLoading: false
        })
        message.error('Failed')
        // 请求失败，处理错误
        console.error('Error fetching data: ', error);
      });
  }

  onExportMjml = (values) => {
    const { exportTemplate } = useExportTemplate();
    exportTemplate(
      this.state.downloadName,
      JsonToMjml({
        data: values.content,
        mode: 'production',
        context: values.content
      }))
  };

  render() {
    const styles = {
      width: '100%',
      height: '100%',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    const smallScene = this.state.windowWidth < 1400;
    if (this.state.loading) {
      return (<div style={styles}>
        <Spin size="large">
          <div className="content" />
        </Spin>
      </div>)
    } else {
      return (
        <EmailEditorProvider
          data={this.state.initialValues}
          fontList={fontList}
          height={'calc(100vh - 72px)'}
          autoComplete
          dashed={false}
          catego
          onUploadImage={this.onUploadImage}
          onSubmit={this.onSubmit}
          mergeTags={this.state.mergeTags}
        >
          {({ values }, { submit }) => {
            return (
              <>
                <PageHeader
                  title={'Edit-' + this.state.data.title}
                  extra={
                    <Stack alignment="center">
                      <Button onClick={() => this.onCopyHtml(values)}>
                        Copy Html
                      </Button>
                      <Button onClick={() => this.onExportMjml(values)}>
                        Export Template
                      </Button>
                      <Button onClick={this.onImportMjml}>
                        import Template
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => submit()}
                        loading={this.state.updateLoading}
                      >
                        Save
                      </Button>
                    </Stack>
                  }
                />
                <StandardLayout
                  compact={!smallScene}
                  showSourceCode={true}
                  categories={defaultCategories}
                >
                  <EmailEditor />
                </StandardLayout>
              </>
            );
          }}
        </EmailEditorProvider>
      );
    }
  }
}



// function Email() {
//   const [downloadFileName, setDownloadName] = useState('download.mjml');
//   const { importTemplate } = useImportTemplate();
//   const { exportTemplate } = useExportTemplate();
//   const [data, setData] = useState(null);
//   const [template, setTemplate] = useState({});
//   const location = useLocation();
//   const { width } = useWindowSize();
//   const [initialValues, setInitalValues] = useState("");
//   const searchParams = new URLSearchParams(location.search);
//   if (!searchParams.get("token") && !searchParams.get("id")) {

//   }
//   const [loading, setLoading] = useState(true);
//   const headers = useMemo(() => {
//     return {
//       'Authorization': searchParams.get("token"),
//       'Content-Type': 'application/json'
//     };
//   }, [searchParams]);
//   var msg = getMessage({
//     ordMsg: searchParams.get("id")
//   }, true, true);
//   useEffect(() => {
//     fetchData();
//     if (!loading && data) {
//       setInitalValues({
//         subject: 'ArmLoop',
//         subTitle: `${data?.msgTitle ?? ''}`,
//         content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
//         ...data?.extInfo,
//       })
//     }
//   }, [loading]);


//   const fetchData = async () => {
//     axios.post('https://ctscan0.armloop.com.au/CTScan/pro/msg/design/get', msg, {
//       headers: headers
//     })
//       .then(response => {
//         if (response.status === 200) {
//           if (response.data.success) {
//             let extInfo = "";
//             try {
//               extInfo = JSON.parse(response.data.result.extInfo);
//             } catch (e) {
//               extInfo = "";
//             }
//             setData({ ...response.data.result, extInfo });
//             setLoading(false);
//           }
//         }
//       })
//       .catch(error => {
//         // 请求失败，处理错误
//         console.error('Error fetching data: ', error);
//       });
//   }


//   const smallScene = width < 1400;

//   const onImportMjml = async () => {
//     try {
//       const [filename, data] = await importTemplate();
//       setDownloadName(filename);
//       setTemplate(data);
//     } catch (error) {
//       message.error('Invalid mjml file');
//     }
//   };

//   const onCopyHtml = (values) => {
//     const html = mjml(JsonToMjml({
//       data: values.content,
//       mode: 'production',
//       context: values.content
//     }), {
//       beautify: true,
//       validationLevel: 'soft',
//     }).html;

//     copy(html);
//     message.success('Copied to pasteboard!')
//   };

//   const onExportMjml = (values) => {
//     exportTemplate(
//       downloadFileName,
//       JsonToMjml({
//         data: values.content,
//         mode: 'production',
//         context: values.content
//       }))
//   };


//   const onUploadImage = async (blob) => {
//     console.log(blob);
//     let id = Date.now();
//     // let id = uuidv4().replaceAll('-', '');
//     var isImage = blob.type.includes("image");
//     var fileName = blob.name;
//     var newFileName = id + fileName.substring(fileName.lastIndexOf("."), fileName.length);
//     var key = 'lot' + '/' + newFileName;
//     // const compressionFile = await (
//     //   await imageCompression
//     // ).default(blob as File, {
//     //   maxWidthOrHeight: 1440,
//     // });
//     // return services.common.uploadByQiniu(compressionFile);
//   };

//   // 提交
//   const onSubmit = useCallback(
//     async (
//       values,
//       form
//     ) => {
//       console.log('values', values)
//       const html = mjml(JsonToMjml({
//         data: values.content,
//         mode: 'production',
//         context: values.content
//       }), {
//         beautify: true,
//         validationLevel: 'soft',
//       }).html;
//       let body = getMessage({
//         ...data,
//         extInfo: JSON.stringify(values),
//         msgTemplate: html,
//       }, true, true);
//       axios.post('https://ctscan0.armloop.com.au/CTScan/pro/msg/design/update', body, {
//         headers: headers
//       })
//         .then(response => {
//           if (response.status === 200) {
//             if (response.data.success) {
//             }
//           }
//         })
//         .catch(error => {
//           // 请求失败，处理错误
//           console.error('Error fetching data: ', error);
//         });
//       message.success('Saved success!')
//     }, [data, headers]
//   );


//   if (!initialValues && loading) {
//     return (
//       <div>Loading...</div>
//     );
//   }

//   return (
//     <EmailEditorProvider
//       data={initialValues}
//       fontList={fontList}
//       height={'calc(100vh - 72px)'}
//       autoComplete
//       dashed={false}
//       catego
//       onUploadImage={onUploadImage}
//       onSubmit={onSubmit}
//     >
//       {({ values }, { submit }) => {
//         return (
//           <>
//             <PageHeader
//               title={'Edit-' + data?.msgTitle}
//               extra={
//                 <Stack alignment="center">
//                   <Button onClick={() => onCopyHtml(values)}>
//                     Copy Html
//                   </Button>
//                   <Button onClick={() => onExportMjml(values)}>
//                     Export Template
//                   </Button>
//                   <Button onClick={onImportMjml}>
//                     import Template
//                   </Button>
//                   <Button
//                     type='primary'
//                     onClick={() => submit()}
//                   >
//                     Save
//                   </Button>
//                 </Stack>
//               }
//             />
//             <StandardLayout
//               compact={!smallScene}
//               showSourceCode={true}
//               categories={defaultCategories}
//             >
//               <EmailEditor />
//             </StandardLayout>
//           </>
//         );
//       }}
//     </EmailEditorProvider>
//   );
// }


export default Poster;
