import { getMessage, getCenterCTScanProUrl } from "../../utils";
import axios from "axios";

export function getAssumeRole(params,token) {
  let baseUrl = getCenterCTScanProUrl();
  let data = getMessage(params, true, true);
  return axios.post(`${baseUrl}/common/getAssumeRole`, data, {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  });
}

export function getPosterMsg(params, token) {
  let baseUrl = getCenterCTScanProUrl();
  let data = getMessage(params, true, true);
  return axios.post(`${baseUrl}/poster/design/get`, data, {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  });
}

export function updatePosterMsg(params, token) {
  let baseUrl = getCenterCTScanProUrl();
  let data = getMessage(params, true, true);
  return axios.post(`${baseUrl}/poster/design/update`, data, {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  });
}

